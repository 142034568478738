import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserManagement.css';

// Access the base URL from the environment variable
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const UserManagement = ({ token }) => {
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/auth/users`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUsers(response.data);
            } catch (error) {
                console.error('There was an error fetching the users!', error);
            }
        };

        fetchUsers();
    }, [token]);

    const handleRoleChange = async (userId, role) => {
        try {
            await axios.patch(`${apiBaseUrl}/api/auth/role/${userId}`, { role }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMessage('User role updated successfully.');
            setUsers(users.map(user => user._id === userId ? { ...user, role } : user));
        } catch (error) {
            setMessage('There was an error updating the user role.');
        }
    };

    const handleApproval = async (userId) => {
        try {
            await axios.patch(`${apiBaseUrl}/api/auth/approve/${userId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setMessage('User approved successfully.');
            setUsers(users.map(user => user._id === userId ? { ...user, approvalStatus: 'approved' } : user));
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setMessage('Access denied. Only admins can approve users.');
            } else {
                setMessage('There was an error approving the user.');
            }
        }
    };

    return (
        <div className="user-management-container">
            <h1>User Management</h1>
            {message && <p className="message">{message}</p>}
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Approval Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>{user.approvalStatus}</td>
                            <td>
                                {user.role === 'admin' ? (
                                    <button className="role-button" onClick={() => handleRoleChange(user._id, 'user')}>Set as User</button>
                                ) : (
                                    <button className="role-button" onClick={() => handleRoleChange(user._id, 'admin')}>Set as Admin</button>
                                )}
                                {user.approvalStatus === 'pending' && (
                                    <button className="approve-button" onClick={() => handleApproval(user._id)}>Approve</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserManagement;