import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import './RecipeDetail.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const RecipeDetail = ({ token }) => {
    const { id } = useParams();
    const [recipe, setRecipe] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [portionSize, setPortionSize] = useState(1); // Default portion size multiplier
    const [checkedIngredients, setCheckedIngredients] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/recipes/${id}`);
                setRecipe(response.data);
            } catch (error) {
                console.error('There was an error fetching the recipe!', error);
            }
        };

        fetchRecipe();
    }, [id]);

    const getUserInfo = () => {
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload;
        }
        return null;
    };

    const userInfo = getUserInfo();
    const isAdmin = userInfo && userInfo.role === 'admin';

    const handleDelete = async () => {
        try {
            await axios.delete(`${apiBaseUrl}/api/recipes/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            navigate('/');
        } catch (error) {
            console.error('There was an error deleting the recipe!', error);
        }
    };

    const adjustPortionSize = (multiplier) => {
        setPortionSize((prevSize) => Math.max(1, prevSize + multiplier));
    };

    const handleCheckboxChange = (index) => {
        setCheckedIngredients((prevChecked) => ({
            ...prevChecked,
            [index]: !prevChecked[index]
        }));
    };

    if (!recipe) {
        return <p>Loading...</p>;
    }

    return (
        <div className="recipe-detail">
            <div className="recipe-header">
                <img src={recipe.imageLocation || 'https://www.veganeat.co.uk/assets/images/logo-grey.png'} alt={recipe.name} className="recipe-image" />
                <div className="recipe-title">
                    <h1>{recipe.name}</h1>
                    <p>By: {recipe.authorName}</p>
                    <p>Difficulty: {recipe.difficulty}</p>
                </div>
            </div>
            <div className="portion-controls">
                <IconButton onClick={() => adjustPortionSize(-1)} disabled={portionSize <= 1}>
                    <RemoveIcon />
                </IconButton>
                <span>Portion Size: {recipe.portionSize}</span>
                <IconButton onClick={() => adjustPortionSize(1)}>
                    <AddIcon />
                </IconButton>
            </div>
            <div className="recipe-details">
                <p><strong>Preparation Time:</strong> {recipe.prepTime}</p>
                <p><strong>Cook Time:</strong> {recipe.cookTime}</p>
                <p><strong>Category:</strong> {recipe.category}</p>
                <h2>Ingredients</h2>
                <ul>
                    {recipe.ingredients.map((ingredient, index) => (
                        <li key={index} className={`ingredient ${checkedIngredients[index] ? 'checked' : ''}`}>
                            <Checkbox
                                checked={checkedIngredients[index] || false}
                                onChange={() => handleCheckboxChange(index)}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                            />
                            <label htmlFor={`ingredient-${index}`}>
                                {(ingredient.details.ingredientAmount * portionSize).toFixed(2)} {ingredient.details.ingredientMeasurement} {ingredient.name}
                            </label>
                                                </li>
                    ))}
                </ul>
                <h2>Instructions</h2>
                <ol>
                    {recipe.instructions.map((instruction, index) => (
                        <li key={index}>{instruction.instruction}</li>
                    ))}
                </ol>
            </div>
            {isAdmin && (
                <div className="admin-buttons">
                    <Link to={`/update/${recipe._id}`} className="update-button">Update</Link>
                    <button onClick={() => setShowModal(true)} className="delete-button">Delete</button>
                </div>
            )}
            {showModal && (
                <ConfirmationModal
                    message="Are you sure you want to delete this recipe?"
                    onConfirm={handleDelete}
                    onCancel={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default RecipeDetail;