import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import '@fortawesome/fontawesome-free/css/all.css';

const Header = ({ token, setToken }) => {
    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('token');
    };

    const getUserInfo = () => {
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            // console.log(payload);
            return payload;
        }
        return null;
    };

    const userInfo = getUserInfo();
    const isAdmin = userInfo && userInfo.role === 'admin';
    const isApproved = userInfo && userInfo.approvalStatus === 'approved';

    return (
        <header>
            <nav>
                <div className="nav-left">
                    <Link to="/">Home</Link>
                    {isApproved && token && (
                        <>
                            <Link to="/create">Create Recipe</Link>
                            {isAdmin && <Link to="/user-management">User Management</Link>}
                        </>
                    )}
                </div>
                <div className="nav-right">
                    {token ? (
                        <>
                            <span className="user-info">
                                <i className="fas fa-user"></i> {userInfo.username}
                            </span>
                            <button className="logout-button" onClick={handleLogout}>Logout</button>
                        </>
                    ) : (
                        <>
                            <Link to="/signup">Sign Up</Link>
                            <Link to="/login">Login</Link>
                        </>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default Header;
