import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RecipeGallery from './components/RecipeGallery';
import CreateRecipe from './components/CreateRecipe';
import UpdateRecipe from './components/UpdateRecipe';
import RecipeDetail from './components/RecipeDetail';
import Signup from './components/Signup';
import Login from './components/Login';
import Header from './components/Header';
import UserManagement from './components/UserManagement';



function App() {
    const [token, setToken] = useState(localStorage.getItem('token'));

    // Access the base URL from the environment variable
    

    const setTokenAndStore = (token) => {
        setToken(token);
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    };

    return (
        <Router>
            <div className="App">
                <Header token={token} setToken={setTokenAndStore} />
                <Routes>
                    <Route path="/" element={<RecipeGallery />} />
                    <Route path="/create" element={token ? <CreateRecipe token={token} /> : <Login setToken={setTokenAndStore} />} />
                    <Route path="/update/:id" element={token ? <UpdateRecipe token={token} /> : <Login setToken={setTokenAndStore} />} />
                    <Route path="/recipe/:id" element={<RecipeDetail token={token} />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login setToken={setTokenAndStore} />} />
                    <Route path="/user-management" element={token ? <UserManagement token={token} /> : <Login setToken={setTokenAndStore} />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
