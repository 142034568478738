import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './CreateRecipe.css';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const CreateRecipe = ({ token }) => {
    const [recipe, setRecipe] = useState({
        name: '',
        difficulty: '',
        prepTime: '',
        cookTime: '',
        category: '',
        portionSize: '',
        ingredients: [{ name: '', details: { ingredientAmount: '', ingredientMeasurement: '' } }],
        instructions: [{ instruction: '' }]
    });
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = e => {
        const { name, value } = e.target;
        setRecipe({ ...recipe, [name]: value });
    };

    const handleIngredientChange = (index, event) => {
        const values = [...recipe.ingredients];
        if (event.target.name === 'name') {
            values[index].name = event.target.value;
        } else {
            values[index].details[event.target.name] = event.target.value;
        }
        setRecipe({ ...recipe, ingredients: values });
    };

    const handleInstructionChange = (index, event) => {
        const values = [...recipe.instructions];
        values[index][event.target.name] = event.target.value;
        setRecipe({ ...recipe, instructions: values });
    };

    const handleAddIngredient = () => {
        setRecipe({
            ...recipe,
            ingredients: [...recipe.ingredients, { name: '', details: { ingredientAmount: '', ingredientMeasurement: '' } }]
        });
    };

    const handleRemoveIngredient = index => {
        const values = [...recipe.ingredients];
        values.splice(index, 1);
        setRecipe({ ...recipe, ingredients: values });
    };

    const handleAddInstruction = () => {
        setRecipe({
            ...recipe,
            instructions: [...recipe.instructions, { instruction: '' }]
        });
    };

    const handleRemoveInstruction = index => {
        const values = [...recipe.instructions];
        values.splice(index, 1);
        setRecipe({ ...recipe, instructions: values });
    };

    const handleFileChange = e => {
        setFile(e.target.files[0]);
    };

    const getUserInfo = () => {
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload;
        }
        return null;
    };

    const userInfo = getUserInfo();

    const handleSubmit = async e => {
        e.preventDefault();
        setErrorMessage('');

        if (!recipe.name) {
            setErrorMessage('Recipe name is required.');
            return;
        }

        if (!recipe.portionSize || isNaN(recipe.portionSize) || recipe.portionSize <= 0) {
            setErrorMessage('Portion size must be a positive number.');
            return;
        }

        if (recipe.ingredients.length === 0 || recipe.ingredients.some(ingredient => !ingredient.name || !ingredient.details.ingredientAmount || !ingredient.details.ingredientMeasurement)) {
            setErrorMessage('Each recipe must have at least one ingredient with name, amount, and measurement.');
            return;
        }

        if (recipe.instructions.length === 0 || recipe.instructions.some(instruction => !instruction.instruction)) {
            setErrorMessage('Each recipe must have at least one instruction.');
            return;
        }

        let imageLocation = '';

        if (file) {
            const formData = new FormData();
            formData.append('image', file);

            try {
                const uploadResponse = await axios.post(`${apiBaseUrl}/api/recipes/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });
                imageLocation = uploadResponse.data.fileUrl;
            } catch (error) {
                console.error('There was an error uploading the file!', error);
                return;
            }
        }

        // Add order to instructions
        const orderedInstructions = recipe.instructions.map((instruction, index) => ({
            ...instruction,
            instructionNumber: index + 1
        }));

        console.log('Instruction:', orderedInstructions);
        const newRecipe = {
            ...recipe,
            instructions: orderedInstructions,
            imageLocation,
            authorName: userInfo.username,
            createdAt: new Date().toISOString()
        };

        try {
            const response = await axios.post(`${apiBaseUrl}/api/recipes`, newRecipe, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate('/');
        } catch (error) {
            console.error('There was an error creating the recipe!', error);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const sourceDroppableId = result.source.droppableId;
        const destinationDroppableId = result.destination.droppableId;

        if (sourceDroppableId === destinationDroppableId) {
            const items = Array.from(
                sourceDroppableId === 'droppable-ingredients'
                    ? recipe.ingredients
                    : recipe.instructions
            );

            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);

            if (sourceDroppableId === 'droppable-ingredients') {
                setRecipe({ ...recipe, ingredients: items });
            } else {
                setRecipe({ ...recipe, instructions: items });
            }
        }
    };

    return (
        <div className="create-recipe-container">
            <h1>Create New Recipe</h1>
            <form className="create-recipe-form" onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" value={recipe.name} onChange={handleChange} />
                </label>
                <label>
                    Difficulty:
                    <input type="text" name="difficulty" value={recipe.difficulty} onChange={handleChange} />
                </label>
                <label>
                    Upload Image:
                    <input type="file" onChange={handleFileChange} />
                </label>
                <label>
                    Preparation Time:
                    <input type="text" name="prepTime" value={recipe.prepTime} onChange={handleChange} />
                </label>
                <label>
                    Cook Time:
                    <input type="text" name="cookTime" value={recipe.cookTime} onChange={handleChange} />
                </label>
                <label>
                    Category:
                    <input type="text" name="category" value={recipe.category} onChange={handleChange} />
                </label>
                <label>
                    Portion Size:
                    <input type="number" name="portionSize" value={recipe.portionSize} onChange={handleChange} />
                </label>

                <h3>Ingredients</h3>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-ingredients">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {recipe.ingredients.map((ingredient, index) => (
                                    <Draggable key={index} draggableId={`draggable-ingredient-${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="ingredient"
                                            >
                                                <div {...provided.dragHandleProps} className="drag-handle">☰</div>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Ingredient Name"
                                                    value={ingredient.name}
                                                    onChange={event => handleIngredientChange(index, event)}
                                                />
                                                <input
                                                    type="text"
                                                    name="ingredientAmount"
                                                    placeholder="Amount"
                                                    value={ingredient.details.ingredientAmount}
                                                    onChange={event => handleIngredientChange(index, event)}
                                                />
                                                <input
                                                    type="text"
                                                    name="ingredientMeasurement"
                                                    placeholder="Measurement"
                                                    value={ingredient.details.ingredientMeasurement}
                                                    onChange={event => handleIngredientChange(index, event)}
                                                />
                                                <button type="button" className="remove-button" onClick={() => handleRemoveIngredient(index)}>Remove</button>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="add-button">
                    <IconButton onClick={handleAddIngredient} color="primary" className="add-icon-button">
                        <AddIcon />
                    </IconButton>
                </div>

                <h3>Instructions</h3>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-instructions">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {recipe.instructions.map((instruction, index) => (
                                    <Draggable key={index} draggableId={`draggable-instruction-${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="instruction"
                                            >
                                                <div {...provided.dragHandleProps} className="drag-handle">☰</div>
                                                <input
                                                    type="text"
                                                    name="instruction"
                                                    placeholder="Instruction"
                                                    value={instruction.instruction}
                                                    onChange={event => handleInstructionChange(index, event)}
                                                />
                                                <button type="button" className="remove-button" onClick={() => handleRemoveInstruction(index)}>Remove</button>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="add-button">
                    <IconButton onClick={handleAddInstruction} color="primary" className="add-icon-button">
                        <AddIcon />
                    </IconButton>
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button type="submit" className="create-button">Create Recipe</button>
            </form>
        </div>
    );
};

export default CreateRecipe;
