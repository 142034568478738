import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './RecipeGallery.css';

// Access the base URL from the environment variable
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

if (!apiBaseUrl) {
    console.error('apiBaseUrl environment variable is not defined!');
    process.exit(1);
}

const placeholderImage = 'https://www.veganeat.co.uk/assets/images/logo-grey.png'; // Replace with your placeholder image URL

const RecipeGallery = ({ token }) => {
    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [visibleRecipes, setVisibleRecipes] = useState(10); // Initial number of visible recipes
    const [loading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState('name'); // Default sort by name
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order ascending
    const observer = useRef();

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/recipes`);
                if (Array.isArray(response.data)) {
                    return response.data;
                } else if (response.data && Array.isArray(response.data.recipes)) {
                    return response.data.recipes;
                } else {
                    console.error('Unexpected response structure:', response.data);
                    return [];
                }
            } catch (error) {
                console.error('There was an error fetching the recipes!', error);
                return [];
            }
        };

        const loadRecipes = async () => {
            const cachedRecipes = localStorage.getItem('recipes');
            const cachedHash = localStorage.getItem('recipesHash');

            const newRecipes = await fetchRecipes();
            const newHash = JSON.stringify(newRecipes);

            if (newHash !== cachedHash) {
                setRecipes(newRecipes);
                localStorage.setItem('recipes', JSON.stringify(newRecipes));
                localStorage.setItem('recipesHash', newHash);
            } else if (cachedRecipes) {
                setRecipes(JSON.parse(cachedRecipes));
            }

            setLoading(false);
        };

        loadRecipes();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisibleRecipes(prevVisibleRecipes => prevVisibleRecipes + 10);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (observer.current && recipes.length > visibleRecipes) {
            const target = document.querySelector('.load-more');
            if (target) {
                observer.current.observe(target);
            }
        }
    }, [recipes, visibleRecipes]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setVisibleRecipes(10); // Reset visible recipes count when search term changes
    };

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
        setVisibleRecipes(10); // Reset visible recipes count when sort option changes
    };

    const handleSortOrderChange = (e) => {
        setSortOrder(e.target.value);
        setVisibleRecipes(10); // Reset visible recipes count when sort order changes
    };

    const sortRecipes = (recipes, sortBy, sortOrder) => {
        return recipes.sort((a, b) => {
            if (sortBy === 'name') {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (nameA > nameB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            } else if (sortBy === 'date') {
                const dateA = new Date(a.dateAdded);
                const dateB = new Date(b.dateAdded);
                return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            }
            return 0;
        });
    };

    const filteredRecipes = recipes.filter(recipe => {
        const searchLower = searchTerm.toLowerCase();
        const nameMatch = recipe.name?.toLowerCase().includes(searchLower);
        const ingredientMatch = recipe.ingredients?.some(ingredient => ingredient.name?.toLowerCase().includes(searchLower));
        return nameMatch || ingredientMatch;
    });

    const sortedRecipes = sortRecipes(filteredRecipes, sortBy, sortOrder);
    const displayedRecipes = sortedRecipes.slice(0, visibleRecipes);

    return (
        <div className="gallery-container">
            <div className="gallery-header">
                <h1>Recipe Gallery</h1>
            </div>
            <div className="search-bar-container">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search by title or ingredients"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="sort-controls">
                    <select value={sortBy} onChange={handleSortChange}>
                        <option value="name">Name</option>
                        <option value="date">Date Added</option>
                    </select>
                    <select value={sortOrder} onChange={handleSortOrderChange}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div> {/* You can style this spinner in your CSS */}
                </div>
            ) : (
                <div className="gallery">
                    {displayedRecipes.map(recipe => (
                        <div className="gallery-item" key={recipe._id}>
                            <Link to={`/recipe/${recipe._id}`}>
                                <img
                                    src={recipe.imageLocation ? recipe.imageLocation : placeholderImage}
                                    alt={recipe.name}
                                    className="gallery-image"
                                />
                            </Link>
                            <div className="gallery-info">
                                <h3>{recipe.name}</h3>
                                <p>By: {recipe.authorName}</p>
                                <p>Difficulty: {recipe.difficulty}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {visibleRecipes < filteredRecipes.length && (
                <div className="load-more">Loading more...</div>
            )}
        </div>
    );
};

export default RecipeGallery;
